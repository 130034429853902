import React from 'react';

export function InstagramIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 30 30"
      {...props}
    >
      <path d="M15 30C6.697 30 0 23.247 0 15 0 6.697 6.753 0 15 0s15 6.753 15 15-6.697 15-15 15zm5.092-21.089a1.11 1.11 0 00-1.107 1.107 1.11 1.11 0 001.107 1.107 1.11 1.11 0 001.107-1.107 1.11 1.11 0 00-1.107-1.107zm-4.926 1.44c-2.546 0-4.65 2.103-4.65 4.649s2.104 4.65 4.65 4.65c2.546 0 4.65-2.104 4.65-4.65 0-2.546-2.104-4.65-4.65-4.65zm0 7.638A2.976 2.976 0 0112.177 15a2.976 2.976 0 012.989-2.989A2.976 2.976 0 0118.155 15c0 1.66-1.384 2.989-2.989 2.989zm9.41-6.864a5.693 5.693 0 00-5.701-5.7h-7.528a5.693 5.693 0 00-5.701 5.7v7.528a5.693 5.693 0 005.7 5.701h7.529a5.693 5.693 0 005.7-5.7v-7.528zm-1.827 7.528a3.945 3.945 0 01-3.93 3.93h-7.527a3.945 3.945 0 01-3.93-3.93v-7.527a3.945 3.945 0 013.93-3.93h7.527a3.945 3.945 0 013.93 3.93v7.527z" />
    </svg>
  );
}
