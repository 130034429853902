import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  height: 100%;
  padding: 0 16px;
  position: relative;

  .productName {
    color: ${({ theme }) => theme.colorsNew.product.regular};
  }

  .card {
    color: ${({ theme }) => theme.colorsNew.card.regular};
  }
  .match {
    color: ${({ theme }) => theme.colorsNew.match.regular};
  }
  .pay {
    color: ${({ theme }) => theme.colorsNew.pay.regular};
  }
  .count {
    color: ${({ theme }) => theme.colorsNew.count.regular};
  }
`;
