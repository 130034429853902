import styled, { css } from 'styled-components';
import { SmartLink } from './SmartLink';
import { MEDIUM } from '../breakpoints';
import Color from 'color';

export const CTAButton = styled(SmartLink).attrs(({ text }) => ({
  children: text,
}))`
  ${({ size }) =>
    size === 'small'
      ? css`
          height: 30px;
          padding: 0 8px;
          ${({ theme }) => theme.typography.cell}
        `
      : css`
          height: 40px;
          padding: 0 20px;
          ${({ theme }) => theme.typography.body}
        `}
  font-weight: bold;
  display: inline-flex;
  padding: 0 ${({ size }) => (size === 'small' ? '8px' : '20px')};
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  min-width: max-content;

  @media (min-width: ${MEDIUM}) {
    width: max-content;
  }

  ${({ isSuccess, type, theme }) => {
    switch (type) {
      case 'default-inverted':
        return css`
          border: 1px solid ${theme.colorsNew.gray.subtleBorder};
          background: ${theme.colorsNew.product.light};
          color: ${theme.colorsNew.product.regular};

          :hover {
            box-shadow: 1px 2px 3px
                ${Color(theme.colorsNew.product.regular)
                  .alpha(0.27)
                  .rgb()
                  .string()},
              1px 2px 2px
                ${Color(theme.colorsNew.product.regular)
                  .alpha(0.36)
                  .rgb()
                  .string()}};
          }
          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.light50};
            color: ${theme.colorsNew.gray.white};
          }
        `;
      case 'product-ghost':
        return css`
          border: 1px solid transparent;
          color: ${theme.colorsNew.product.regular};

          :hover {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: rgba(1, 167, 123, 0.07);
          }
          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: rgba(1, 167, 123, 0.7);
            color: ${theme.colorsNew.gray.white};
          }
        `;
      case 'product-outline':
        return css`
          border: 1px solid ${theme.colorsNew.product.regular};
          color: ${theme.colorsNew.product.regular};

          :hover {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.light30};
            color: ${theme.colorsNew.gray.white};
          }
          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.dark};
            color: ${theme.colorsNew.gray.white};
          }
        `;
      case 'accent-outline':
        return css`
          border: 1px solid ${theme.colorsNew.product.accent};
          color: ${theme.colorsNew.product.accent};

          :hover {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.accent};
            color: ${theme.colorsNew.gray.black};
          }

          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.dark};
            color: ${theme.colorsNew.gray.white};
          }
        `;
      case 'accent':
        return css`
          border: 1px solid ${theme.colorsNew.gray.subtleBorder};
          color: ${theme.colorsNew.gray.black};
          background: ${theme.colorsNew.product.accent};

          :hover {
            color: ${theme.colorsNew.gray.black};
          }

          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.dark};
            color: ${theme.colorsNew.gray.white};
          }
        `;
      case 'accent-ghost':
        return css`
          border: 1px solid transparent;
          color: ${theme.colorsNew.product.accent};

          :hover {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: rgba(105, 245, 165, 0.07);
          }

          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: rgba(105, 245, 165, 0.7);
            color: ${theme.colorsNew.gray.black};
          }
        `;
      case 'white-outline':
        return css`
          border: 1px solid ${theme.colorsNew.gray.white};
          color: ${theme.colorsNew.gray.white};

          :hover {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.gray.white};
            color: ${theme.colorsNew.gray.black};
          }

          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.dark};
            color: ${theme.colorsNew.gray.white};
          }
        `;
      case 'white':
        return css`
          border: 1px solid ${theme.colorsNew.gray.subtleBorder};
          color: ${theme.colorsNew.gray.black};
          background: ${theme.colorsNew.gray.white};

          :hover {
            color: ${theme.colorsNew.gray.black};
          }

          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.dark};
            color: ${theme.colorsNew.gray.white};
          }
        `;
      case 'white-ghost':
        return css`
          border: 1px solid transparent;
          color: ${theme.colorsNew.gray.white};

          :hover {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: rgba(255, 255, 255, 0.07);
          }

          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: rgba(255, 255, 255, 0.7);
            color: ${theme.colorsNew.gray.black};
          }
        `;
      case 'black':
        return css`
          border: 1px solid ${theme.colorsNew.gray.subtleBorder};
          color: ${theme.colorsNew.gray.white};
          background: ${theme.colorsNew.gray.black};

          :hover {
            border: 1px solid ${theme.colorsNew.gray.black};
            color: ${theme.colorsNew.gray.black};
            background: transparent;
          }

          :active {
            background: ${theme.colorsNew.product.dark};
            color: ${theme.colorsNew.gray.black};
          }
        `;
      default:
        return css`
          border: 1px solid ${theme.colorsNew.gray.subtleBorder};
          background: ${isSuccess
            ? theme.colorsNew.product.light50
            : theme.colorsNew.product.regular};
          color: ${isSuccess
            ? theme.colorsNew.product.dark
            : theme.colorsNew.gray.white};

          :hover {
            box-shadow: 1px 2px 3px
                ${Color(theme.colorsNew.product.regular)
                  .alpha(0.27)
                  .rgb()
                  .string()},
              1px 2px 2px
                ${Color(theme.colorsNew.product.regular)
                  .alpha(0.36)
                  .rgb()
                  .string()}};
          }
          :active {
            border: 1px solid ${theme.colorsNew.gray.subtleBorder};
            background: ${theme.colorsNew.product.dark};
            color: ${theme.colorsNew.gray.white};
          }
        `;
    }
  }}
`;
