import React from 'react';

export function LinkedinIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 31 31"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M.757 15.892c0 8.272 6.727 15 15 15 8.327 0 15.054-6.728 15-15 0-8.272-6.729-15-15-15-8.273 0-15 6.728-15 15zm9.485-4.522a1.82 1.82 0 100-3.64 1.82 1.82 0 000 3.64zm14.007 5.68v5.625c0 .166-.11.276-.276.276h-2.922c-.166 0-.276-.11-.276-.276v-5.239c0-1.378-.496-2.316-1.71-2.316-.937 0-1.489.607-1.764 1.269-.11.22-.11.496-.11.827v5.46c0 .165-.11.275-.276.275h-2.923c-.166 0-.276-.11-.276-.276 0-.344.004-1.02.008-1.868.013-2.548.034-6.637-.008-7.837 0-.166.11-.276.276-.276h2.867c.166 0 .276.11.276.276v1.213c.441-.717 1.269-1.71 3.088-1.71 2.372-.055 4.026 1.434 4.026 4.577zM8.863 22.896h2.923c.165 0 .276-.11.276-.276v-9.706c0-.165-.11-.275-.276-.275H8.863c-.165 0-.275.11-.275.276v9.705c.055.166.165.276.275.276z"
        clipRule="evenodd"
      />
    </svg>
  );
}
