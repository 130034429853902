import React from 'react';

export function YouTubeIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM11.4408 7.76879C13.3033 7.71497 14.9833 7.71438 15.0001 7.71438C15.0169 7.71438 16.697 7.71497 18.5595 7.76844C19.6533 7.80016 20.5953 7.84352 21.359 7.8977C22.3135 7.96538 22.9908 8.04999 23.372 8.14929C23.8272 8.26894 24.2418 8.50193 24.5743 8.82492C24.9068 9.14791 25.1455 9.54957 25.2665 9.98964C25.498 10.8283 25.6073 12.146 25.6584 13.1037C25.7138 14.1413 25.7144 14.9916 25.7144 15.0001C25.7144 15.0086 25.7144 15.8589 25.6584 16.8963C25.5658 18.6316 25.3942 19.5474 25.2665 20.0103C25.1454 20.4504 24.9067 20.852 24.5742 21.175C24.2417 21.498 23.8272 21.731 23.372 21.8507C22.9904 21.9494 22.3136 22.0345 21.359 22.1021C20.5953 22.1564 19.6534 22.1998 18.5595 22.2314C16.6969 22.2852 15.0168 22.2858 15.0001 22.2858C14.9835 22.2858 13.3034 22.2852 11.4408 22.2314C10.3469 22.1999 9.40497 22.1565 8.64122 22.1025C7.68655 22.0348 7.00936 21.9502 6.62815 21.851C6.17301 21.7313 5.75844 21.4983 5.42598 21.1753C5.09351 20.8523 4.85481 20.4506 4.73379 20.0106C4.50234 19.1719 4.393 17.8542 4.34187 16.8965C4.28647 15.8589 4.28586 15.0086 4.28586 15.0001C4.28586 14.9916 4.28647 14.1413 4.34187 13.1037C4.4344 11.369 4.60607 10.4524 4.73379 9.98999C4.85481 9.54992 5.09352 9.14826 5.42601 8.82527C5.75851 8.50228 6.1731 8.26929 6.62827 8.14964C7.00984 8.05082 7.68667 7.96574 8.64134 7.89805C9.40497 7.84388 10.3469 7.8004 11.4408 7.76879ZM12.8086 11.9251V18.0753L18.4092 15.0001L12.8086 11.9251Z"
      />
    </svg>
  );
}
