import React from 'react';

export function TwitterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 31 31"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M.496 15.893c0 8.241 6.704 15 15 15 8.242 0 15-6.759 15-15 0-8.297-6.758-15-15-15-8.296 0-15 6.703-15 15zm21.868-5c.605-.11 1.21-.22 1.759-.495.03 0 .043-.016.05-.013.005.002.005.018.005.068 0 .055 0 .165-.11.275-.385.55-.824 1.044-1.374 1.428-.11.055-.22.11-.22.22 0 2.857-.769 5.77-2.857 7.857-2.692 2.693-6.703 3.407-10.274 2.418-.66-.165-1.32-.44-1.979-.77-.11-.054-.206-.123-.302-.192a2.263 2.263 0 00-.302-.192c-.055-.055-.11-.11-.055-.11h.22c.989.11 2.033-.055 2.967-.384.55-.165 1.648-.55 1.978-1.044-.11-.055-.275-.055-.385-.055-.989-.275-2.252-.77-2.857-2.418 0-.055 0-.11.11-.11.412.028.687.028.893.028.206 0 .343 0 .48.027-.11-.055-.274-.11-.439-.165-1.209-.494-2.088-1.703-2.253-2.967v-.44c0-.054 0-.11.11-.054.605.33 1.319.385 1.374.385-.082-.055-.151-.124-.22-.193s-.137-.137-.22-.192c-1.044-1.044-1.428-3.077-.604-4.34.11-.11.11-.11.22 0 1.923 2.197 4.34 3.241 7.198 3.68.02 0 .034 0 .042-.006.012-.01.012-.036.012-.103-.054-.495-.054-.99.056-1.484.11-.55.384-.989.714-1.428.33-.44.769-.77 1.209-.99.44-.274.989-.384 1.483-.439.495 0 1.044.055 1.539.275.33.11.659.33.989.604l.164.165c.055.055.11.055.165.055.715-.22 1.429-.44 2.088-.824h.11v.11c-.11.384-.33.714-.604 1.044-.165.22-.605.714-.88.769z"
        clipRule="evenodd"
      />
    </svg>
  );
}
