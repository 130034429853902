import React from 'react';

export function FacebookIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="currentColor"
      viewBox="0 0 31 31"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M.757 15.893c0 8.296 6.758 15 15 15 8.296 0 15-6.759 15-15 0-8.297-6.759-15-15-15-8.297 0-15 6.758-15 15zm12.637 8.736v-8.682h.055v-.054h-1.593c-.11 0-.22-.11-.22-.22v-2.638c0-.11.11-.22.22-.22h1.593v-2.582a3.213 3.213 0 013.242-3.242h3.022c.11 0 .22.11.22.22V9.85c0 .11-.11.22-.22.22H17.9a.845.845 0 00-.825.823v1.869h2.583c.11 0 .22.11.22.22l-.22 2.637c0 .11-.11.22-.22.22h-2.363v8.79c0 .11-.11.22-.22.22h-3.241c-.11 0-.22-.11-.22-.22z"
        clipRule="evenodd"
      />
    </svg>
  );
}
