import React from 'react';
import { Link } from 'gatsby';

export function SmartLink({ href, children, ...rest }) {
  if (href?.startsWith('/')) {
    return (
      <Link activeClassName="active" to={href} {...rest}>
        {children}
      </Link>
    );
  }

  if (href?.startsWith('#')) {
    return (
      <a href={href} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  );
}
