import React from 'react';

export function MediumIcon() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0908 0C6.80655 0 0.0908203 6.71573 0.0908203 15C0.0908203 23.2843 6.80655 30 15.0908 30C23.3751 30 30.0908 23.2843 30.0908 15C30.0908 6.71573 23.3751 0 15.0908 0ZM7.96582 10.2857C8.11582 10.433 8.19082 10.6541 8.19082 10.8751V18.612C8.26582 18.9067 8.19082 19.2015 7.96582 19.4225L6.09082 21.7067V22.0015H11.4158V21.7804L9.54082 19.4962C9.39082 19.2751 9.24082 18.9804 9.31582 18.6857V11.9067L14.0408 22.0015H14.5658L18.6158 11.9067V19.8646C18.6158 20.0857 18.6158 20.0857 18.4658 20.233L17.0408 21.633V21.9278H24.0908V21.633L22.6658 20.3067C22.5158 20.233 22.5158 20.0857 22.5158 19.9383V9.99094C22.5158 9.84357 22.5158 9.6962 22.6658 9.62252L24.0908 8.2962V8.00146H19.1408L15.6158 16.6962L11.5658 8.00146H6.31582V8.2962L7.96582 10.2857Z"
      />
    </svg>
  );
}
