import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CellText } from '@increasecard/typed-components';
import { SmartLink } from '../../components/SmartLink';
import { useLocation } from '@reach/router';

export function HeaderLink({ href, name, sublinks, variant }) {
  if (!sublinks || sublinks.length === 0) {
    return <HeaderLinkLink href={href}>{name}</HeaderLinkLink>;
  }

  return (
    <HeaderLinkWithSubmenu
      href={href}
      name={name}
      variant={variant}
      sublinks={sublinks}
    />
  );
}

function HeaderLinkWithSubmenu({ href, name, variant, sublinks }) {
  const location = useLocation();
  const someChildActive = sublinks.some(({ url }) => location.pathname === url);
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <button
      onMouseEnter={() => setMenuVisible(true)}
      onMouseLeave={() => setMenuVisible(false)}
      style={{ position: 'relative', padding: '16px 0' }}
    >
      <HeaderLinkLink
        className={someChildActive ? 'active' : undefined}
        href={href}
        variant={variant}
      >
        {name}
      </HeaderLinkLink>

      <HeaderSubmenu
        role="tooltip"
        style={{
          position: 'absolute',
          top: '48px',
          zIndex: 1,
          display: menuVisible ? 'unset' : 'none',
        }}
      >
        {sublinks.map(
          ({ name, url, visible }) =>
            visible !== false && (
              <HeaderLinkSublink href={url} key={url}>
                {name}
              </HeaderLinkSublink>
            )
        )}
      </HeaderSubmenu>
    </button>
  );
}

const HeaderLinkLink = styled(CellText).attrs({ as: SmartLink })`
  text-transform: uppercase;
  &.active {
    font-weight: bold;
    ${({ variant, theme }) =>
      variant === 'enterprise' &&
      css`
        color: ${theme.colorsNew.increase.accent};
      `}
  }
  :hover {
    text-decoration: underline;
    ${({ variant, theme }) =>
      variant === 'enterprise' &&
      css`
        color: ${theme.colorsNew.increase.accent};
      `}
  }
`;

const HeaderSubmenu = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.colorsNew.gray.white};

  border: 1px solid ${({ theme }) => theme.colorsNew.gray.grayLight};

  box-shadow: ${({ theme }) => theme.shadows.elevate};
  border-radius: 15px;
  min-width: max-content;
`;

const HeaderLinkSublink = styled(CellText).attrs({ as: SmartLink })`
  color: ${({ theme }) => theme.colorsNew.gray.grayBold};
  font-weight: bold;
  width: 100%;

  :not(:first-child) {
    padding-top: 8px;
  }

  :not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  }

  :hover {
    color: ${({ theme }) => theme.colorsNew.increase.regular};
  }
`;
