import React from 'react';
import { ThemeProvider } from '@increasecard/typed-components';
import { HeaderContainer as Header } from './Header/HeaderContainer';
import { FooterContainer as Footer } from './Footer/FooterContainer';
import { ContentWrapper } from '../components/ContentWrapper';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';

const RECAPTCHA_KEY = '6LdqS_0UAAAAACsvD0jEyKbaGUdM1r-RG5j7KFzf';

export function Layout({
  children,
  variant,
  productName,
  headerLayout,
  footerLayout,
}) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language="es">
      <Helmet
        link={[
          { rel: 'preconnect', href: 'https://vars.hotjar.com' },
          { rel: 'preconnect', href: 'https://script.hotjar.com' },
          { rel: 'preconnect', href: 'https://big.g.doubleclick.net' },
          { rel: 'preconnect', href: 'https://snap.licdn.com' },
          { rel: 'preconnect', href: 'https://connect.facebook.net' },
          { rel: 'preconnect', href: 'https://googleadservices.com' },
          { rel: 'preconnect', href: 'https://in.hotjar.com' },
          { rel: 'preconnect', href: 'https://track.hubspot.com' },
          { rel: 'preconnect', href: 'https://p.adsymptotic.com' },
          { rel: 'preconnect', href: 'https://api.hubapi.com' },
          { rel: 'icon', type: 'image/svg', href: '/favicon.svg' },
        ]}
      />
      <ThemeProvider productName={productName}>
        <ContentWrapper variant={variant}>{children}</ContentWrapper>
        <Footer layout={footerLayout || 'fullNavigation'} />
        <Header
          variant={variant}
          productName={productName}
          layout={headerLayout || 'fullNavigation'}
        />
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}
