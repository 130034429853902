import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Header } from './Header';
export function HeaderContainer(props) {
  const { links, whatsappHref } = useStaticQuery(graphql`
    query {
      strapi {
        header {
          whatsappHref
          links {
            name
            url
            id
            visible
            sublinks {
              id
              name
              url
              visible
            }
          }
        }
      }
    }
  `).strapi.header;
  return <Header {...props} links={links} whatsappHref={whatsappHref} />;
}
