import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '@increasecard/typed-components';
import { MenuIcon, CloseIcon } from '@increasecard/icons';
import styled, { css } from 'styled-components';
import { SmartLink } from '../../components/SmartLink';
import { MEDIUM } from '../../breakpoints';
import { CTAButton } from '../../components/CTAButton';

function getButtonType({ variant }) {
  switch (variant) {
    case 'enterprise':
    case 'increase':
      return 'accent-outline';
    case 'increase-dark':
      return 'white-outline';
    default:
      return 'product-outline';
  }
}

const Wrapper = styled.div`
  color: ${({ variant, theme }) =>
    variant === 'enterprise'
      ? theme.colorsNew.gray.white
      : theme.colorsNew.gray.black};
  margin-left: 16px;

  @media (min-width: ${MEDIUM}) {
    display: none;
  }
`;

const Drawer = styled.div`
  display: flex;
  ${({ open }) => (open ? 'transform: none;' : 'transform: translateX(100%);')}
  position: fixed;
  padding: 72px 0;
  top: 0;
  right: 0;
  transition: transform 200ms ease-in-out;
  min-width: 300px;
  width: 80vw;
  height: 100vh;
  overflow: auto;
  ${({ theme, variant }) =>
    variant === 'enterprise'
      ? css`
          background: ${theme.colorsNew.gray.black};
          color: ${theme.colorsNew.gray.white};
        `
      : css`
          background: ${theme.colorsNew.gray.white};
          color: ${theme.colorsNew.gray.black};
        `};
`;

const Backdrop = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.gray.blackMedium
      : theme.colorsNew.gray.black};
  opacity: 0.45;
`;

const HeaderMobileLink = styled(SmartLink)`
  ${({ theme }) => theme.typography.H3}
  display: block;
  width: 100%;
  padding: 8px 0;
  border-left: 3px solid transparent;
  padding-left: 13px;

  &.active {
    border-left: 3px solid
      ${({ theme, variant }) =>
        variant === 'enterprise'
          ? theme.colorsNew.increase.accent
          : theme.colorsNew.increase.regular};
  }
`;

const HeaderMobileMainLink = styled(HeaderMobileLink)`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.increase.accent
      : theme.colorsNew.increase.regular};

  :not(:first-child) {
    margin-top: 16px;
  }
`;

const HeaderMobileSublink = styled(HeaderMobileLink)`
  color: ${({ theme, variant }) =>
    variant === 'enterprise'
      ? theme.colorsNew.gray.white
      : theme.colorsNew.gray.black};
`;

export function HeaderMobileMenu({ variant, links }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  useOnClickOutside(ref, () => setOpen(false));
  const toggleOpen = () => setOpen(open => !open);
  return (
    <Wrapper variant={variant}>
      <button
        style={{ display: 'inline-flex', alignItems: 'center' }}
        onClick={toggleOpen}
      >
        <MenuIcon height="24" width="24" />
      </button>
      {open && <Backdrop variant={variant} />}
      <Drawer open={open} ref={ref} variant={variant}>
        <button
          onClick={() => setOpen(false)}
          style={{ position: 'fixed', top: '24px', right: '20px' }}
        >
          <CloseIcon height="24" width="24" />
        </button>
        <ul style={{ width: '100%' }}>
          <li>
            <CTAButton
              type={getButtonType({ variant })}
              size="small"
              text="Iniciar sesión"
              href="https://auth.increase.app/login"
              style={{
                width: 'max-content',
                marginBottom: '8px',
                marginLeft: '16px',
              }}
            />
          </li>
          {links.map(({ url, name, sublinks }) => (
            <React.Fragment key={name}>
              <li style={{ margin: '24px 0 8px', width: '100%' }}>
                <HeaderMobileMainLink href={url} variant={variant}>
                  {name}
                </HeaderMobileMainLink>
              </li>
              {sublinks &&
                sublinks.map(({ url, name }) => (
                  <li key={name} style={{ width: '100%' }}>
                    <HeaderMobileSublink href={url} variant={variant}>
                      {name}
                    </HeaderMobileSublink>
                  </li>
                ))}
            </React.Fragment>
          ))}
        </ul>
      </Drawer>
    </Wrapper>
  );
}
