import React from 'react';

export function WhatsappIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.98671 17.9536C7.41603 17.9536 5.86522 17.536 4.51324 16.7407L0.994106 17.9536L1.70986 14.2555C0.596463 12.7047 0 10.8954 0 8.98672C0 4.03607 4.03607 0 8.98671 0C13.9374 0 17.9734 4.03607 17.9734 8.98672C17.9535 13.9374 13.9374 17.9536 8.98671 17.9536ZM4.65241 15.2893L4.93076 15.4683C6.14357 16.2238 7.5552 16.6413 8.96683 16.6413C13.1818 16.6413 16.6016 13.2216 16.6016 9.0066C16.6214 4.77171 13.2017 1.3321 8.98671 1.3321C4.77171 1.3321 1.3321 4.77171 1.3321 8.98672C1.3321 10.6966 1.8888 12.3269 2.96243 13.6789L3.14137 13.9175L2.74373 15.9455L4.65241 15.2893Z"
        fill="currentColor"
      />
      <path
        d="M4.45355 5.52748C4.45355 5.52748 4.99037 4.61291 5.40789 4.55326C5.8453 4.49361 6.38212 4.49361 6.54117 4.77196C6.70023 5.05031 7.35634 6.68065 7.35634 6.68065C7.35634 6.68065 7.47563 6.959 7.29669 7.23735C7.11776 7.5157 6.72011 7.91334 6.72011 7.91334C6.72011 7.91334 6.50141 8.19169 6.72011 8.48992C6.93882 8.78815 7.27681 9.34485 7.99257 10.0407C8.68844 10.7366 10.0404 11.2535 10.0404 11.2535C10.0404 11.2535 10.2392 11.2734 10.3585 11.1541C10.4778 11.0348 11.1538 10.1998 11.1538 10.1998C11.1538 10.1998 11.3725 9.92143 11.7304 10.0805C12.0883 10.2395 13.6192 11.0149 13.6192 11.0149C13.6192 11.0149 13.7981 11.0746 13.7981 11.3529C13.7981 11.6313 13.6789 12.2874 13.4602 12.5061C13.2414 12.7248 12.5853 13.4207 11.5912 13.4207C10.5971 13.4207 8.25104 12.6254 6.99846 11.3529C5.74589 10.1004 4.63249 8.82791 4.37402 7.67475C4.09567 6.52159 4.11555 6.00465 4.45355 5.52748Z"
        fill="currentColor"
      />
    </svg>
  );
}
