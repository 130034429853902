import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Footer } from './Footer';
export function FooterContainer(props) {
  const {
    links,
    reducedNavigationLinks,
    socialLinks,
    fiscalDataUrl,
  } = useStaticQuery(graphql`
    query {
      strapi {
        footer {
          links {
            id
            name
            url
            visible
            sublinks {
              id
              name
              url
              visible
            }
          }
          reducedNavigationLinks {
            id
            name
            url
            visible
            sublinks {
              id
              name
              url
              visible
            }
          }
          socialLinks {
            facebook
            instagram
            linkedin
            twitter
            youTube
            medium
          }
          fiscalDataUrl
        }
      }
    }
  `).strapi.footer;
  return (
    <Footer
      {...props}
      links={links}
      reducedNavigationLinks={reducedNavigationLinks}
      socialLinks={socialLinks}
      fiscalDataUrl={fiscalDataUrl}
    />
  );
}
