import styled, { css } from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { HEADER_HEIGHT } from '../constants';

export const ContentWrapper = styled.div`
  overflow: hidden;
  ${({ theme, variant }) => {
    switch (variant) {
      case 'enterprise':
        return css`
          background: ${theme.colorsNew.gray.black};
          color: ${theme.colorsNew.gray.white};
        `;
      case 'increase':
        return css`
          background: ${theme.colorsNew.increase.dark30};
          color: ${theme.colorsNew.gray.white};
        `;
      case 'increase-dark':
        return css`
          background: ${theme.colorsNew.increase.dark};
          color: ${theme.colorsNew.gray.white};
        `;
      default:
        return css`
          background: ${theme.colorsNew.gray.white};
          color: ${theme.colorsNew.gray.black};
        `;
    }
  }}
  padding-top: 72px;

  @media (min-width: ${MEDIUM}) {
    padding-top: ${HEADER_HEIGHT};
  }
`;
